import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser'
import Configuration from '../../../../domain/services/ConfigurationService'
import { useFeatures } from '../../providers/FeatureProvider'
import { convertDatesToEpochSeconds } from './convertDatesToEpochSeconds'
import { CustomerIo } from './useCustomerIo'

let analyticsSingleton: AnalyticsBrowser | null = null

const getCustomerIo = (): AnalyticsBrowser => {
  if (!analyticsSingleton) {
    analyticsSingleton = AnalyticsBrowser.load({
      writeKey: Configuration.CUSTOMER_IO_JAVASCRIPT_WRITE_KEY,
    })
  }
  return analyticsSingleton
}

export const useCustomerIo = (): CustomerIo => {
  const { features } = useFeatures()

  const identify = (id: string, userProfile: Record<string, any>) => {
    if (!features.CUSTOMER_IO) return
    if (!id) console.warn('Cannot identify without id')

    getCustomerIo()
      .identify(id, {
        id: userProfile.id,
        email: userProfile.email,
        created_at: convertDatesToEpochSeconds(userProfile.createdOn),
        organization: userProfile.organization,
        professionalRoles: userProfile.professionalRoles,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .catch((error) => {
        console.error('Customer.io identify error:', error)
      })
  }

  const track = (name: string, properties: Record<string, any>) => {
    if (!features.CUSTOMER_IO) return
    if (!name) console.warn('Cannot †rack without name')

    properties = convertDatesToEpochSeconds(properties)

    getCustomerIo()
      .track(name, properties)
      .catch((error) => {
        console.error('Customer.io track error:', error)
      })
  }

  return {
    identify,
    track,
  }
}

export default useCustomerIo
