import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Heading, Text, VStack } from 'native-base'
import { useState } from 'react'
import { GroupTypes } from '../../../../domain/models/interfaces/activityInterfaces'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import {
  useGetLiveAndUpcoming,
  useGetRecentlyAdded,
} from '../../queries/activitiesQueries'
import { useCollections } from '../../queries/collectionsQueries'
import { NavigationParams } from '../../screens/NavigationScreen'
import FadeInView from '../_shared/FadeInView'
import { SkeletonLoader } from '../_shared/SkeletonLoader'
import ActivityModalPreview from './activities/ActivityModalPreview'
import CategoryFilterView from './CategoryFilterView'
import CollectionsExplore from './CollectionsExplore'
import LiveAndUpcoming from './LiveAndUpcoming'
import PopularTagButtons from './PopularTagButtons'
import RecentlyAdded from './RecentlyAdded'
import TimeFilterRowView from './TimeFilterRowView'

const isGroup = (type) => GroupTypes.includes(type)

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" p={6}>
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
    <SkeletonLoader mb={6} size={{ width: '100%', height: '88px' }} />
  </VStack>
)

const ExploreView = () => {
  const { liveAndUpcoming, isLoading } = useGetLiveAndUpcoming()
  const { recentlyAdded, isLoading: isLoadingRecently } = useGetRecentlyAdded()
  const { data: collectionsData, isLoading: isCollectionsLoading } =
    useCollections()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isDesktop = useDesktopBreakpoint()
  const isMobile = useMobileBreakpoint()

  const [modalData, setModalData] = useState(null)
  const [modalShown, setModalShown] = useState(false)
  const handleCloseModal = () => setModalShown(false)

  const marginX = isMobile ? '16px' : '40px'

  if (isLoading || isLoadingRecently || isCollectionsLoading) {
    return <LoadingView />
  }

  const onPressActivity = (activity) => {
    if (isDesktop && !isGroup(activity.type)) {
      return navigation.navigate('Activity', { activityId: activity.id })
    }
    setModalData(activity)
    setModalShown(true)
  }

  return (
    <FadeInView>
      <VStack my={isMobile ? '24px' : isDesktop ? '0px' : '40px'}>
        <VStack
          px={isDesktop ? '40px' : 0}
          pt={isDesktop ? '40px' : 0}
          pb={isDesktop ? '24px' : 0}
          space="24px"
          mx={!isDesktop ? marginX : undefined}>
          <LiveAndUpcoming
            data={liveAndUpcoming}
            onPressActivity={onPressActivity}
          />
          <RecentlyAdded
            data={recentlyAdded}
            onPressActivity={onPressActivity}
          />
        </VStack>
        <VStack mx={marginX} my={isDesktop ? 0 : '24px'}>
          <CollectionsExplore data={collectionsData.slice(0, 3)} />
        </VStack>
        <VStack
          px={isDesktop ? '40px' : 0}
          pb={isDesktop ? '40px' : 0}
          space="24px"
          mx={!isDesktop ? marginX : undefined}>
          <Box>
            <Text fontSize="14px" color="primary.900" fontWeight="500">
              Browse by time
            </Text>
            <TimeFilterRowView />
          </Box>
          <Box>
            <Text fontSize="14px" color="primary.900" fontWeight="500" mb={2}>
              Browse by category
            </Text>
            <CategoryFilterView
              horizontalMargin={isMobile || isDesktop ? 24 : 40}
              inExploreScreen={true}
              onPressFilter={(filter) => {
                navigation.navigate('Search', {
                  categoryFilters: filter.index,
                })
              }}
            />
          </Box>

          <Heading size="sm" color="primary.900">
            Popular topics
          </Heading>
          <PopularTagButtons />
        </VStack>
      </VStack>

      <ActivityModalPreview
        modalData={modalData}
        modalShown={modalShown}
        handleCloseModal={handleCloseModal}
        bottomSheetStyling={{
          hasDivider: false,
          width: 400,
          top: isDesktop ? '0px' : null,
          hasCloseOnBottom: false,
        }}
      />
    </FadeInView>
  )
}

export default ExploreView
