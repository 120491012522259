import { useNavigation } from '@react-navigation/native'
import { Button, Flex, HStack, Text } from 'native-base'
import { useState } from 'react'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import Configuration from '../../../../../domain/services/ConfigurationService'
import useAnalytics from '../../../hooks/useAnalytics'
import BaseLayout from '../../../layouts/BaseLayout'
import { useCamerasContext } from '../../../providers/CameraProvider'
import { useMicrophonesContext } from '../../../providers/MicrophoneProvider'
import { useRoomContext } from '../../../providers/Room30Provider'
import { useUserProfileContext } from '../../../providers/UserProfileProvider'
import { useCompleteDailyRec } from '../../home/queries'
import OrientationStepper from '../../room/orientation/OrientationStepper'
import Card from '../../_shared/card/Card'
import FixedContainer from '../../_shared/layout/FixedContainer'
import TextLink from '../../_shared/text/TextLink'
import LiveGroupHeading from '../LiveGroupHeading'
import VideoPreview from '../VideoPreview'
import AliasEntry from './AliasEntry'
import DeviceSettings from './DeviceSettings'
import JoinRoom from './JoinRoom'

const Orientation = () => {
  const { join, info, me } = useRoomContext()
  const { selectedMicrophone } = useMicrophonesContext()
  const { selectedCamera } = useCamerasContext()
  const { completeGroup } = useCompleteDailyRec()
  const analytics = useAnalytics()
  const { userProfile } = useUserProfileContext()

  const navigation = useNavigation()
  const [stepperIndex, setStepperIndex] = useState(0)

  const isModerator = me?.role === AttendeeRole.Moderator

  const disableEnterRoomButton =
    selectedMicrophone?.name === 'No microphone' ||
    !selectedMicrophone ||
    selectedCamera?.name === 'No camera' ||
    !selectedCamera

  const handleGoToNextStep = [
    () => setStepperIndex((prevIndex) => prevIndex + 1),
    () => setStepperIndex((prevIndex) => prevIndex + 1),
    () => setStepperIndex((prevIndex) => prevIndex),
  ]

  const handleGoToPrevStep = [
    () => navigation.goBack(),
    () => setStepperIndex((prevIndex) => prevIndex - 1),
    () => setStepperIndex((prevIndex) => prevIndex - 1),
  ]

  const doJoin = () => {
    join()
    if (me.role !== AttendeeRole.Moderator) {
      analytics.track('Group Attended', {
        Duration: info.duration,
        'Detail Link': `${Configuration.CABANA_UI}/activity?activityId=${info.id}`,
        ID: info.id,
        Moderator: info.moderator,
        Title: info.title,
        Type: info.type,
        Start: new Date(info.startTime),
        'Total Groups Attended': userProfile.attendedGroupCount + 1,
      })
      completeGroup({ activityId: info.id })
    }
  }

  return (
    <BaseLayout>
      <FixedContainer size="lg">
        <LiveGroupHeading info={info} />
        <Flex mt="5">
          {isModerator ? (
            <>
              <HStack space={3}>
                <Flex flex={2}>
                  <Card
                    boxProps={{
                      w: '650px',
                    }}
                    title="Preview"
                    hasPadding={{ title: true, actions: true, body: false }}
                    body={<VideoPreview />}
                  />
                </Flex>
                <Flex flex={1}>
                  <Card
                    boxProps={{
                      h: '100%',
                    }}
                    title="Audio settings"
                    body={<DeviceSettings />}
                  />
                </Flex>
              </HStack>
              <HStack alignSelf="flex-end" mt="3">
                <Button
                  variant="outline"
                  borderColor="muted.300"
                  tintColor="#222B60"
                  textDecorationColor="black"
                  onPress={navigation.goBack}>
                  Cancel
                </Button>
                <Button
                  isDisabled={disableEnterRoomButton}
                  colorScheme="primary"
                  ml="3"
                  onPress={() => doJoin()}>
                  Enter room
                </Button>
              </HStack>
            </>
          ) : (
            <OrientationStepper
              titles={[
                'Enter your nickname',
                'Audio settings',
                "You're ready to go!",
              ]}
              steps={[
                <AliasEntry key={0} />,
                <DeviceSettings key={1} />,
                <JoinRoom handlePress={() => doJoin()} key={2} />,
              ]}
              stepConditions={[me?.alias, me?.alias, me?.alias]}
              stepperIndex={stepperIndex}
              handleGoToNextStep={handleGoToNextStep}
              handleGoToPrevStep={handleGoToPrevStep}
              setStepperIndex={setStepperIndex}
            />
          )}
        </Flex>
        {!isModerator && (
          <Flex mt="4" alignItems="center">
            <Flex>
              <Text bold color="primary.900" textAlign="center">
                Experiencing a crisis?
              </Text>
              <Text color="primary.900" textAlign="center">
                <TextLink href="https://www.yourcabana.com/resources">
                  View our resources
                </TextLink>{' '}
                for immediate support
              </Text>
            </Flex>
          </Flex>
        )}
      </FixedContainer>
    </BaseLayout>
  )
}

export default Orientation
